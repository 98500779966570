<template>
    <div class="memberClub">
        <!-- 会员俱乐部 -->
		<common-head url="/newHome" :title="$t('tba.MemberClub')" :is-return="true"></common-head>
		
		<div class="layoutBox bgWhite">
			<van-row class="userInfo">
			    <van-col span="5">
			        <!-- <img class="userHead" v-if="vipInfo.avatar" :src="vipInfo.avatar"/>
			        <img class="userHead" v-else src="@/assets/imgs/login/rainbow.png"/> -->
			        <img class="userHead" :src="vipInfo.avatar"/>
					<div class="level" v-if="vipInfo.active">
						<img src="@/assets/imgs/icon/icon_vip.png" alt="" />
						<span>VIP {{ vipInfo.level }}</span>
					</div>
			    </van-col>
			    <van-col span="17">
			        <p class="userName">{{username}}</p>
			        <div class="vipBox">
			            <p class="expireDay" v-if="vipInfo.active">
			                <span class="span1">{{ vipInfo.expire_day }}</span><span class="span2">{{ $t('tba.deadline')}}</span>
			            </p>
			            <p class="expireDay" v-else><span class="span2">{{ $t('tba.youarenotVip')}}</span></p>
			        </div>
			    </van-col>
			</van-row>
			
			<div class="banli">
			    <p class="mcTitle">{{ $t('tba.handlingMembership')}}</p>
			    <van-row gutter="15">
			        <van-col
			                span="8"
			                v-for="(item, index) of dataList"
			                :key="index"
			                @click="ChangeBiZhong(index)"
			        >
			            <div class="changeStyles" :class="active == index ? 'checked' : ''">
			                <p class="dataTitle">{{ item.name }}</p>
			                <p class="dataPrice">{{ item.price }}</p>
			                <p class="dataPriceOld">{{ item.raw_price }}</p>
			            </div>
			        </van-col>
			    </van-row>
			    <van-button @click="showbuy" class="buttonOk">{{ $t('tba.yes')}}</van-button>
			</div>
			
			<van-dialog
			        v-model="show"
			        show-cancel-button
			        :title="card_type"
			        @confirm="buyCard"
			>
			    <p class="diglogPStyle1">
					<span class="label">{{ $t('tba.PurchaseQuantity') }}：</span>
					<input v-model="num" autofocus="autofocus" type="number" class="psdInput" />
				</p>
			    <p class="diglogPStyle1">
					<span class="label">{{ $t('tba.AvailableBalance') }}：</span>
					<span style="color: #459c86;">{{ balance }}</span> USDT
			    </p>
			</van-dialog>
		</div>
        
    </div>
</template>

<script>
	import commonHead from '@/components/commonHead';

    export default {
        name: "myActivationCode",
        components: {commonHead},
        data() {
            return {
                num: 1,
                dataList: [],
                active: 0,
                tradepwd: "",
                balance: 0,
                username: '',
                type: 1,
                card_type: "",
                show: false,
				// vip会员信息
				vipInfo: {},
            };
        },
        mounted() {
            this.username = JSON.parse(localStorage.getItem('userInfo')).username || ''
            this.getListActiveCode()
            this.getClubMemberInfo();
            this.getMyAsset();
        },
        methods: {
			
			// 获取用户USDT的可用余额
			getMyAsset() {
			    this.$post2("User/Api/Index/userAsset", {})
			        .then((res) => {
			            this.balance = res.USDT.amount.toFixed(6);
			        })
			        .catch((e) => {
			            this.$toast.fail(e);
			        });
			},
			
			// 验证是否为会员用户，获取用户会员等级信息
			getClubMemberInfo() {
			    this.$post2("User/Api/Index/getClubInfo", {}).then((res) => {
					this.vipInfo = res
			    });
			},
			
			// 获取会员卡类型列表
			getListActiveCode() {
			    this.$post2("User/Api/Index/getMemberSettingV2").then((res) => {
			        this.dataList = res;
			    });
			},
			
			// 切换选取的会员卡类型
			ChangeBiZhong(index) {
			    this.active = index;
			    this.type = this.dataList[index].type;
			},
			// 获取选择的会员卡类型
			getType() {
			    this.type = this.dataList[this.active].type;
			    this.card_type = this.dataList[this.active].name;
			},
            
			// 点击确定办理，弹出办理会员数量输入框
            showbuy() {
                this.getType();
                this.show = true;
            },
			
			// 点击 办理会员 确定按钮，传递参数给接口
			buyCard() {
			    this.getType();
				
				let unitPrice = this.dataList[this.active].price.split('U')
				let vipFee = this.num * unitPrice[0]
				// console.log(vipFee)
				if (vipFee > this.balance) {
					this.$toast('余额不足')
					return
				}

				this.$router.push({
					path: '/checkClubVerifyCode',
					query: {
						type: this.type,
						num: this.num,
					}
				})
			},
			
        },
    };
</script>

<style lang="less" scoped>
    .memberClub {
        .diglogPStyle {
            margin: 0.2rem 0.2rem 0.2rem 2rem;
			font-size: 16px;
        }
        .dialogStyle {
            margin: 0.4rem 0.4rem 0.1rem 0.4rem;
            padding:0 0 0.2rem;
            border-bottom: 1px solid #f3f0f3;
        }
        .diglogPStyle1{
            font-size: 16px;
            // margin: 0.4rem 0.4rem 0.1rem 0.4rem;
			margin: .3rem .4rem;
            // padding: 0 0 0.2rem;
			display: flex;
			.label {
				// width: 5em;
				width: 9em;
			}
			.psdInput {
				font-size: 16px;
				border: 0;
				flex: 1;
				display: block;
				width: 100%;
			}
        }

        .buttonOk{
            margin-top: 4.5rem;
            color: #FFF;
			font-size: .32rem;
            border: 0;
            // border-radius: 5px;
            width: 100%;
			height: .88rem;
			line-height: .88rem;
			background: #90C4FF;
			border-radius: .12rem;
        }
        .userInfo {
            border-bottom: 0.12rem solid #F5F5F5;
            overflow: hidden;
            padding: 0.2rem 0.5rem;
            position: relative;
			.level {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: -.2rem;
				img {
					width: .4rem;
					height: .4rem;
					display: block;
					margin-right: -.25rem;
					position: relative;
					z-index: 2;
				}
				span {
					color: #FFFFFF;
					font-size: .12rem;
					width: 1rem;
					height: .24rem;
					line-height: .24rem;
					padding-left: .3rem;
					background: linear-gradient(139deg, #BBDDFF 0%, #AFC2FF 100%);
					border: 1px solid #FFFFFF;
					border-radius: .16rem;
					display: block;
					position: relative;
					z-index: 1;
				}
			}

            .userHead {
                border-radius: 50%;
                width: 1.2rem;
                height: 1.2rem;
                display: block;
            }

            .userName {
                font-size: 0.36rem;
				font-family: Bahnschrift;
				line-height: .5rem;
                // font-weight: bold;
				padding-top: .15rem;
            }

            .vipBox {
                margin-top: 0.1rem;


                .vipLevel {
                    width: 1rem;
                    height: 0.4rem;
                    position: relative;
                    font-size: 0.24rem;
                    font-family: Bahnschrift;
                    display: inline-block;
                    color: #C1581B;

                    img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        display: block;
                        width: 100%;
                        height: 100%;
                    }

                    span {
                        position: relative;
                        z-index: 11;
                        display: block;
                        text-align: center;
                        line-height: 0.4rem;
                        font-weight: bold;
                        font-size: 0.26rem;
                    }
                }

                .expireDay {
                    // display: inline-block;
					color: #666666;
					line-height: .35rem;

                    .span1 {
                        font-size: 0.24rem;
                        font-family: Bahnschrift;
                        font-weight: normal;
                    }

                    .span2 {
                        margin-left: 0.1rem;
                        font-size: 0.24rem;
                        // font-weight: 500;
                        // color: #737373;
                        // opacity: 0.7;
                    }
                }
            }
        }

        .banli {
            padding: 0.5rem 0.3rem;

            .mcTitle {
                font-size: 0.32rem;
				line-height: .45rem;
                // font-weight: 500;
                color: #373737;
				font-weight: bold;
				margin-bottom: .25rem;
            }
            .changeStyles{
                border: 1px solid #94C7FC;
                border-radius: .12rem;
                text-align: center;
                padding: 0.35rem 0;
				overflow: hidden;
				&.checked {
					color: #fff;
					background-image: linear-gradient(135deg, rgba(148, 199, 252, 1) 0%, rgba(235, 226, 254, 1) 100%);
					position: relative;
					&::after {
						content: '';
						position: absolute;
						right: 0;
						bottom: 0;
						width: .6rem;
						height: .6rem;
						background: url(../../assets/imgs/icon/member_icon_bottom.png) no-repeat right bottom;
						background-size: contain;
					}
					.dataTitle, 
					.dataPrice, 
					.dataPriceOld {
						color: #FFFFFF;
					}
				}
            }
            .mainBackColor1 p{
                color: #fff;
            }
            .dataTitle{
				color: #666666;
                font-size: 0.28rem;
                // font-weight: bold;
                // color: #000000;
            }
            .dataPrice{
				color: #90C4FF;
                font-size: 0.44rem;
                // font-weight: bold;
				font-family: Bahnschrift;
				margin-top: 0.35rem;
            }
            .dataPriceOld{
				color: #999999;
                font-size: 0.32rem;
                // font-weight: 500;
				font-family: Bahnschrift;
                text-decoration: line-through;
				margin-top: 0.2rem;
            }
        }
    }
</style>
