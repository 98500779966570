import { render, staticRenderFns } from "./memberClub.vue?vue&type=template&id=ca985282&scoped=true&"
import script from "./memberClub.vue?vue&type=script&lang=js&"
export * from "./memberClub.vue?vue&type=script&lang=js&"
import style0 from "./memberClub.vue?vue&type=style&index=0&id=ca985282&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca985282",
  null
  
)

export default component.exports